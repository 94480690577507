@import "./variables";

:root {
  //site
  --site-background: #dedede;
  --logo-font-family: "Noto Sans", sans-serif;
  --primary-font-family: "Noto Sans", sans-serif;
  // navbar height
  --navbar-height: 50px;
  --navbar-height-mobile: 50px;
  // colors
  // rgb(14, 127, 165)
  --primary-gradient-bg: linear-gradient(
    90deg,
    rgba(79, 81, 140, 1) 0%,
    rgb(62, 63, 110) 100%
  );
  --primary-backdrop-bg: rgba(0, 0, 0, 0.9);
  --primary-color: #4f518c;
  --secondary-color: #1a2f38;
  --tertiary-color: #0f1c21;
  --light-color: #fff;
  --medium-color: #686868;
  --invalid-color: #f26672;

  // border colors
  --input-border-color: rgb(165, 165, 165);
  --input-border-color-active: var(--primary-color);
  --primary-border-color: rgb(215, 215, 215);

  // CreatePostModal z-index
  --createPostModalZ: 12;

  //input background-color
  --input-background: #fafafa;
  --input-background-darker: #ececec;

  // PostModal z-index
  --postModalZ: 101;
  --postModalContainerZ: 100;
  --postModalBackdropZ: 99;

  // PostOptions z-index
  --optionsModalZ: 200;
  --optionsModalContainerZ: 202;
  --optionsModalBackdropZ: 201;

  --primary-border-radius: 3px;
  --primary-btn-border-radius: 3px;
  --profile-picture-border-radius: 50%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: normal;
  font-family: var(--primary-font-family);
}

body {
  margin: 0;
  background-color: $site-background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

h1,
h2,
h3 {
  font-weight: 600;
}

button {
  cursor: pointer;
  background: none;
  border: none;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

input,
button,
textarea {
  font-size: 1rem;
}

input,
textarea {
  border-radius: calc(var(--primary-border-radius) / 2);
  background: none;
}
