// backgrounds
$site-background: #fafafa;

//colors
$light-color: #fff;
$medium-color: #686868;
$dark-color: #262626;
$primary-color: #0095f6;
$invalid-color: #f26672;

// border color
$border-color: #dbdbdb;
$border-color-dark: #8e8e8e;

//input background-color
$input-background: #fafafa;
$input-background-darker: #ececec;

// font weights
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

// headers font size
$HTwoFontSize: 19px;

// paragraphs font size
$PTagFontSizeRegular: 14px;
$PTagFontSizeSmall: 12px;

// navbar height
$navbar-height: 50px;
$navbar-height-mobile: 50px;

// CreatePostModal z-index
$createPostModalZ: 12;

// PostModal z-index
$postModalZ: 101;
$postModalContainerZ: 100;
$postModalBackdropZ: 99;

// PostOptions z-index
$optionsModalZ: 200;
$optionsModalContainerZ: 202;
$optionsModalBackdropZ: 201;
